export const OrderType = {
  B2C: "B2C",
  B2B: "B2B",
  PARTNER: "Partner",
  DELHI_TENDOR: "Delhi Tender",
};
export const PO_STATUS = {
  confirmed: "Confirmed",
  pending: "Pending",
  rejected: "Rejected",
};
export const OrderTypeLabel = ["", "B2C", "B2B", "PARTNER", "DELHI TENDER"];
export const OrderStatus = {
  INIT: "Initialized",
  CONFIRMED: "Confirmed",
  WANT_TO_CANCEL: "Want to cancel",
  CANCELLED: "Cancelled",
  DISPATCHED: "Dispatched",
  PARTIAL_DELIVERED: "Partial Delivered",
  DELIVERED: "Delivered",
  INSTALLED: "Installed",
  REFUNDED: "Refunded",
  hold: "On Hold",
  config_pending: "Configuration pending",
  write_off: "Write Off",
};
export const OrderStatusLabel = [
  "",
  "Initialized",
  "Confirmed",
  "Want to cancel",
  "Cancelled",
  "Dispatched",
  "Partial Delivered",
  "Delivered",
  "Installed",
  "Refunded",
];
export const PaymentStatus = {
  PENDING_PAYMENT: "Pending Payment",
  ADVANCE_PAYMENT: "Partial Paid",
  FULL_PAYMENT: "Full Paid",
};
export const DiscountType = { COUPON: "Coupon", DIRECT: "Direct" };
export const CouponType = {
  GENERIC: "Generic",
  SPECIFIC: "Specific",
  REFERER: "Referer",
};
export const DiscountValueType = { AMOUNT: "Amount", PERCENT: "Percent" };
// export const CouponValueTypeLabel = ["", "AMOUNT", "PERCENT"];
export const ProductType = { CHARGING_STATION: 0 };
export const TransactionType = {
  REFUND: "Refund",
  PAYMENT: "Payment",
  // BALANCE: "Balance",
};
export const AddressType = { HOME: "Home", OFFICE: "Office", OTHER: "Other" };
// export const AddressTypeLabel = ["", "Home", "Office", "Other"];
export const HostType = { USER: 0, DEALER: 1, DISTRIBUTOR: 2 };
export const OrderItemStatus = {};
export const TransactionStatus = {
  PENDING: "Pending",
  SUCCESS: "Success",
  FAILED: "Failed",
};
export const TransactionStatusLabe = ["", "Pending", "Success", "Failed"];
// exports={OrderType,CouponType,DiscountType,TransactionType,ProductType,OrderStatus,TransactionStatus}
export const MODULE = {
  access_panel: "Access panel",
  user: "User",
  emp: "Emp",
  role: "Role",

  complaint_admin_mail: "Tickets admin mail",

  complaint_panel: "Tickets panel",
  complaint: "Tickets",
  all_compaint: "All Tickets",
};
export const MODULE1 = {
  USER: 1,
  ROLE: 2,
  ORDER: 3,
  CONTENT: 4,
  PAYMENT: 5,
  COMMENT: 6,
  COMMUNICATION: 7,
  INVOICE: 8,
  EXPORTDATA: 9,
  MODIFYCART: 10,
  ANALYTICS: 11,
  TNX_AC_MAIL: 12,
  TNX_OP_MAIL: 13,
  TNX_ADMIN_MAIL: 14,
  COMPLAINT: 15,
  CUSTOMER: 16,
  SALES_FORCE: 17,
  INVENTORY: 18,
  INSTALLED_DEVICES: 19,
  HOST: 20,
  DEVICES: 21,
  OPS_PANEL: 22,
  CONTENT_PANEL: 23,
  API_KEY: 24,
  ALL_ORDERS: 25,
  ORDER_PANEL: 26,
  TXN_PANEL: 27,
  INVENTORY_PANEL: 28,
  HRM_PANEL: 29,
  SALES_FORCE_PANEL: 30,
  ACCESS_PANEL: 31,
  OPS_TICKET: 32,
  OPS_BASKET: 33,
  OPS_TASK: 34,
  COMPLAINT_PANEL: 35,
  HR_OVERVIEW: 36,
  HR_EMPLOYEES: 37,
  HR_EMP_LEAVE: 38,
  MASTER_DATA: 39,
  ALL_COMPLAINTS: 40,
  ALL_OPS_TICKETS: 41,
  INSTALLATION_SAFTY_CHECK: 42,
  MEDIA_FILE: 43,
  COMPLAINT_ADMIN_MAIL: 44,
  ACADEMY: 45,
};
export const MODULELABEL = [
  "",
  "Users",
  "Roles",
  "Orders",
  "Content",
  "Payment",
  "Comment",
  "Communication",
  "Invoice",
  "Export data",
  "Modify cart",
  "Analytics",
  "Tnx AC mail",
  "Tnx ops mail",
  "Tnx admin mail",
  "Complaint",
  "Customer",
  "Sales force",
  "Inventory",
  "Installed Devices",
  "Hosts",
  "Devices",
  "Ops panel",
  "Content panel",
  "API key",
  "All Order",
  "Order Panel",
  "Txn Panel",
  "Inventory Panel",
  "HRM Panel",
  "Sales Force Panel",
  "Access Panel",
  "Ops Tickets",
  "Ops Basket",
  "Ops Task",
  "Complaint Panel",
  "HR OVERVIEW",
  "HR EMPLOYEES",
  "HR EMP LEAVE",
  "Master Data",
  "All Complaints",
  "All Ops Tickets",
  "Insallation/Safty check",
  "Media Files",
  "Complaint Admin Mail",
  "Academy",
];
export const ACTION = {
  READ: "Read",
  CREATE: "Create",
  UPDATE: "Update",
  DELETE: "Delete",
  ALL: "All",
};
export const ACTION1 = {
  CREATE: 1,
  READ: 2,
  UPDATE: 3,
  DELETE: 4,
  ALL: 5,
};

export const ACTIONLABEL = ["", "Create", "Read", "Update", "Delete", "All"];
export const OrderCreateStates = {
  LOADING: 0,
  LOADED: 1,
  ERROR: 2,
};
export const OrderCreatePages = {
  ADDITEMPAGE: 0,
  CUSTOMERDETAILPAGE: 1,
};

export const PaymentRefType = {
  order: "order",
  subscription: "subscription",
};
export const PaymentMode = {
  Cash: "Cash",
  cheque: "Cheque",
  debit_card: "Debit Card",
  credit_card: "Credit Card",
  upi: "UPI",
  netbanking: "Net Banking",
};
export const PaymentModeLabe = [
  "",
  "Cash",
  "Cheque",
  "Debit Card",
  "Credit card",
  "UPI",
];

// export const Departments = [
//   "Management",
//   "Accounts",
//   "Sales",
//   "Tech",
//   "Content",
//   "Operation",
// ];
export const SubDepartments: string[] = [
  "Finance",
  "Legal",
  "Compliance",
  "Operations",
  "Sales",
  "Management",
];
export const Department = [
  {
    department: "Management",
    designation: [
      "Managing director",
      "Manager",
      "Product Manager",
      "Sales Manager",
    ],
  },
  {
    department: "Tech",
    designation: [
      "Backend Developer",
      "Front-End Developer",
      "Tech Lead",
      "IOT Developer",
      "Mobile Developer",
      "Full Stack Developer",
    ],
  },
  {
    department: "Sales",
    designation: ["Sales Head", "Inside Sale"],
  },
  {
    department: "Content Team",
    designation: ["Founders' Office", "Content Intern"],
    designing: ["UI/UX"],
  },
];
export const FileType = {
  UNKOWN: "Unkown",
  IMAGE: "Image",
  VIDEO: "Video",
  PDF: "PDF",
  CSV: "CSV",
};

export const checkList = {
  b2b: {
    brochureSent: 1,
    aggreementSigned: 2,
    deviceTraning: 3,
  },
  b2c: {
    mail: 4,
    whatsapp: 5,
    call: 6,
    deviceTraning: 7,
  },
};
export const checkListLabel = [
  "",
  "Brochures sent",
  "Aggreement signed",
  "Device traning",
  "Welcome mail",
  "Welcome whatsapp",
  "Welcome call",
  "Device traning",
];
export const checkListName = [
  "",
  "brochure",
  "aggreement",
  "traning",
  "mail",
  "whatsapp",
  "call",
  "traning",
];
export const LeadSource = [
  "Facebook",
  "Google",
  "Website",
  "Self",
  "Outbound",
  "Support phone",
  "Indiamart",
  "Amazon",
  "Flipkart",
  "Other",
];
export const sale_type = ["Free of cost", "Sale", "Demo", "Park plus charge"];
export const remarks = [
  "wants to cancel",
  "Recent order",
  "Ready to pay",
  "Spot not finalized",
  "Ringing",
  "Call Back",
  "Not Reachable",
  "Awaiting LEV AC & ARAI Certification",
  "Agreement Pending",
  "Fund Issue",
  "Site under construction/Wiring pending",
  "Channel partner need to find end customer",
  "Awaiting Mini with Sim",
  "Awaiting Mini with BLE",
  "Apartment not giving permission to install",
];
export const orderCategories = [
  "Home",
  "School/College/Institute",
  "Society/Apartment",
  "Fleet",
  "Shop/Mall/Restaurant/Showroom",
  "High way/Petrol pump",
  "Office/Work place",
  "Other",
];
export const orderSegment = [
  "OEM",
  "Retail Chain",
  "Dealer",
  "Battery Swapping",
  "E-Rickshaw",
  "RWA",
  "Fleet",
  "CPO",
  "Enterprise",
  "Inside Sales",
  "Operations",
  "Online/E-commerce",
  "Other",
];

export const complaintStatus = {
  pending: "Pending",
  under_progress: "Under Progress",
  solved: "Solved",
  noted_for_future: "Noted for future",
};
export const PriorityType = {
  low: "Low",
  medium: "Medium",
  high: "High",
};
export const complaint_by = [
  "Procurement",
  "Operations",
  "Sales",
  "Management",
  "Finance",
  "Legal",
];
export const complaint_related_to = [
  "Invoicing",
  "Payments - Procurement",
  "Payments - Operations",
  "Payments - Others",
  "Compliance related - TDS",
  "Compliance related - GST",
  "Ledger Request for Customer/Vendor",
  "Customer Reconciliation",
  "Vendor Ledger Reconciliation",
  "MIS - Collections Report",
  "MIS - Procurement Consumption Report",
  "MIS - Daily Cash Summary",
  "MIS - Cash Flow Statement",
  "MIS - Outstanding Report",
  "MIS - Invoicing Summary",
  "MIS - Revenue Flash",
  "MCA Compliance",
  "Legal Agreements",
];

export const ComplaintTicketType = {
  incident: "Incident",
  service_request: "Service Request",
};
export const OpsOperation = {
  delivery: "Delivery",
  installation: "Installation",
  courier: "Courier",
};
export const OpsTicketStatus = {
  pending: "Pending",
  picked: "Picked",
  completed: "Completed",
  returned: "Returned",
  missing: "Missing",
  defect: "Defect",
  cancel: "Cancel",
};

export const OpsBasketStatus = {
  active: "Active",
  deactive: "Deactive",
};
export const InventoryStatus = {
  stocked: "Stocked",
  installed: "Installed",
  delivered: "Delivered",
  defect: "Defect",
  missing: "Missing",
  alloted: "Alloted",
  returnedNStocked: "Returned and Stocked",
};

export const LeaveType = {
  paid_leave: "Earned Leave",
  medicle_leave: "Medical Leave",
  restricted_holiday: "Restricted Holiday",
  work_from_home: "Work From Home",
  unpaid_leave: "Unpaid Leave",
};

export const EmpType = {
  Fulltime: "Fulltime",
  Intern: "Intern",
};

export const LeaveTypeIntern = {
  medicle_leave: "Medical Leave",
  paid_leave: "Earned Leave",
  restricted_holiday: "Restricted Holiday",
  work_from_home: "Work From Home",
  unpaid_leave: "Unpaid Leave",
};

export const LeavePeriod = {
  full_day: "Full Day",
  first_half: "First Half",
  second_half: "Second Half",
};

export const LeaveRequestStatus = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
  cancelled: "Cancelled",
};

export const EmpDocCategory = {
  gmc: "GMC",
  pf: "PF",
  personal: "Personal",
};
export const EmpDocType = {
  aadhaar_card: "Aadhaar Card",
  pan_card: "Pan Card",
  bank_details: "Bank Details",
  // driving_licence: "Driving Licence",
  education: "Education",
  passport: "Passport",
  // resignation: "Resignation letter",
  // salary_slip: "Salary slips",
  signed_off: "Signed off letter",
  // experience: "Experience letter",
  // gmc_policy: "Policy",
  // gmc_claim: "Claim Process",
  gmc_card: "E-Card",
  // pf_form: "Form",
  // pf_pan: "Pan",
  // pf_aadhar: "Aadhar",
  // pf_bank_details: "Bank Details",
  // pf_epf: "EPF",
  // pf_passport: "Passport",
};

export const EmpDocStatus = {
  pending: "Pending",
  verified: "Verified",
  rejected: "Rejected",
};

// dummy line manager
export const LineManager = [
  "Swapnajit Banerjee",
  "Vignesh Gowtham",
  "Vaibhav Dagar",
];
// dummy hod
export const Hod = ["Vaibhav Tyagi", "Akshay Shekhar", "Paras"];

// HRM Weekdays
export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// demo zones
export const zones = {
  north: "North zone",
  south: "South zone",
  west: "West zone",
  east: "East zone",
  central: "Central zone",
  all: "All",
};

export const SubsidyStatus = {
  claim_submtted: "Claim submitted",
  subsidy_credited: "Subsidy Credited",
  regitration_done: "Registration done",
  site_photo_uploaded: "Site photos and invoice uploaded",
  facilitation_paid: "facilitation charges paid to bses",
  registered_on_portal: "Registered on GNCTD portal",
};

export const QcStatus = {
  pending: "Pending",
  rejected: "Rejected",
  completed: "Completed",
};

export const attachement_belong = {
  order: "order",
  complaint: "complaint",
  task: "task",
};

export const Ops_task_status = {
  pending: "Pending",
  under_progress: "Under Progress",
  completed: "Completed",
  cancelled: "Cancelled",
};
export const file_dir: { [key: string]: string } = {
  order: "order_attachments",
  complaint: "complaint_attachments",
  task: "task_attachments",
  pocuremnt_attachments: "procurement_attachments",
  party_attachments: "party_attachments",
};

export const complaintLogs = {
  created: "created",
  updated: "updated",
  pending: "pending",
  under_progress: "under progress",
  noted_for_future: "noted for future",
  solved: "solved",
  commented: "commented",
};

export const inventoryLogs = {
  created: "created",
  updated: "updated",
  deleted: "deleted",
  installed: "installed",
  delivered: "delivered",
  returned: "returned",
  missing: "missiing",
  defect: "defect",
  commented: "commented",
  complaint: "complaint",
  org_changed: "org changed",
};

export const academyVideoCategory = {
  General: "General",
  Cms: "Cms",
  Podcasts: "Podcasts",
  Tally: "Tally",
  Sales: "Sales",
  Company_Updates: "Company Updates",
  Life_at_kazam: "Life at Kazam",
  Startup_gyaan: "Startup gyaan",
  Ev_news: "Electric Vehicle News",
  Ev_reviews: "Electric Vehicle Reviews",
  Success_story: "Success story",
  host_driver_review: "Host and Driver Review",
};
export const OrderLogType = {
  created: "created",
  updated: "updated",
  order_attachment_added: "order attachment added",

  customer_update: "customer update",
  address_update: "address update",
  added_invoice: "added invoice",
  cust_mail: "cust mail",
  item_update: "item update",
  item_added: "item added",
  item_deleted: "item deleted",
  discount_update: "discount update",
  discount_added: "discount added",
  discount_deleted: "discount deleted",
  extra_update: "extra update",
  extra_added: "extra added",
  extra_deleted: "extra deleted",
  payment_added: "payment added",
  payment_updated: "payment updated",
  payment_deleted: "payment deleted",
  payment_approved: "payment approved",
  payment_rejected: "payment rejected",
  payment_pending: "payment pending",
  payment_attachment_added: "payment attachment added",
  alloted_device_delivered: "alloted device delivered",
  alloted_device_installed: "alloted device installed",
  alloted_device_returned: "alloted device returned",
  created_task: "created task",
  created_complaint: "created complaint",
  commented: "commented",
  comment_deleted: "comment deleted",
};

export const TaskType = {
  instalation: "Installation",
  delivery: "Delivery",
  service: "Service",
  configuration: "Configuration",
  complaint: "Complaint",
  survey: "Survey",
  scout: "Scout",
  uninstallation: "Uninstallation",
  other: "Other",
};
